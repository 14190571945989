<template>
  <div>
    <div class="d-flex ">
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group
          label="Identité :"
          label-for="Identité :"
        >
          <validation-provider
            #default="{ errors }"
            name="Identité :"
            rules="required"
          >
            <b-form-input
              v-model="students.id"
              :state="errors.length > 0 ? false : null"
              placeholder="Identité :"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group
          label="Nom de l'étudiant :"
          label-for="Nom de l'étudiant :"
        >
          <validation-provider
            #default="{ errors }"
            name="Nom de l'étudiant :"
            rules="required"
          >
            <b-form-input
              v-model="students.username"
              :state="errors.length > 0 ? false : null"
              placeholder="Nom de l'étudiant :"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group
          label="Prénom de l'étudiant :"
          label-for="Prénom de l'étudiant :"
        >
          <validation-provider
            #default="{ errors }"
            name="Prénom de l'étudiant :"
            rules="required"
          >
            <b-form-input
              v-model="students.username"
              :state="errors.length > 0 ? false : null"
              placeholder="Prénom de l'étudiant :"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group
          label="Télephone de l'étudiant :"
          label-for="Télephone de l'étudiant :"
        >
          <validation-provider
            #default="{ errors }"
            name="Télephone de l'étudiant :"
            rules="required"
          >
            <b-form-input
              v-model="students.phone"
              :state="errors.length > 0 ? false : null"
              placeholder="Télephone de l'étudiant :"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </div>

    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Liste des transactions :
    </h4>
    <div class="d-flex ">
      <b-col
        md="4"
        class="mt-2"
      >
        <b-form-group>
          <b-button
            :to="{ name: 'payments-add', params: { id: students.id} }"
            variant="gradient-primary"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Ajouter une transaction</span>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group>
          <b-button
            variant="gradient-warning"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="DollarSignIcon" />
            <span class="ml-1">Reste à payer</span>
            <span
              v-if="paiements.length != 0"
              class="ml-1"
            >{{ paiements[0].rest_payer }}</span>
            <span
              v-else
              class="ml-1"
            >{{ inscriptions[0].parcour.frais_scolaire }}</span>
          </b-button>
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        class="mt-2"
      >
        <b-form-group>
          <b-button
            variant="gradient-success"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="DollarSignIcon" />
            <span class="ml-1">Total payer</span>
            <span
              v-if="paiements.length != 0"
              class="ml-1"
            >{{ paiements[0].total_payer }}</span>
            <span
              v-else
              class="ml-1"
            >0</span>
          </b-button>
        </b-form-group>
      </b-col>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="paiements"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-ediit-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1 text-success"
              size="16"
              @click="generateReport(props.row)"
            />
            <b-tooltip
              title="Imprimer préinscription"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-ediit-icon`"
            />
          </span>
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Attestation"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
          >
            <section slot="pdf-content">
              <html
                lang="en"
                dir="ltr"
              >

                <body style="margin:1cm;background-color:white;color:black">

                  <div style="display:flex; justify-content:space-between ;align-items:center">
                    <div>
                      <img
                        src="/logo-mit.png"
                        alt="mit"
                        width="200px"
                        height="auto"
                      >
                    </div>
                    <div>
                      <h5
                        style="color: black;
                text-align: center;
                font-weight: bold"
                      >
                        Ecole polytechnique mediterraneenne  <br> privée de tunis
                      </h5>
                    </div>
                  </div>
                  <div>
                    <hr>

                    <table
                      style="border: 1px solid black;   width: 100%;
                border-collapse: collapse;"
                    >
                      <tr>
                        <th colspan="3"> UMLT
                        </th>

                      </tr>
                      <tr>
                        <td colspan="2">
                          <p>Payé par: <b>{{ studentt.username }}</b>
                          </p></td>
                        <td style=" text-align:center">Année universitaire
                          <br>
                          <b>{{ new Date().getFullYear() }}/{{ new Date().getFullYear()+1 }}</b></td>
                      </tr>

                      <tr rowspan="9">
                        <th colspan="2">
                          <p style="text-align:right">INE:   {{ currentStudent.reference }}</p>
                          <p style="text-align:left">Etudiant : {{ studentt.username }} <br>
                            CIN/Passeport :   {{ studentt.cin }}<br>
                            Inscription : {{ currentStudent.parcour_name }}</p>
                          <p>Motif payement: {{ currentStudent.reason_payment }}</p></th>
                        <td><b>MONTANT</b></td>

                      </tr>
                      <tr>
                        <td rowspan="5"> <p style="text-align:center">Cachet et signature </p>
                          <br>
                          <p style="text-align:center">Service Financier</p>
                          <br>
                          <p style="text-align:left">N.B : joindre une copie du chèque ou du virement à ce reçu</p>

                        </td>
                        <td>Espèce</td>
                        <td><b>{{ currentStudent.amount }}<b /></b></td>
                      </tr>
                      <tr>
                        <td>Chèque</td>
                        <td />
                      </tr>
                      <tr>
                        <td>Virement</td>
                        <td />
                      </tr>
                      <tr>
                        <td>Traite</td>
                        <td />
                      </tr>
                      <tr>

                        <td>Total en TND
                        </td>
                        <td>{{ currentStudent.amount }}</td>
                      </tr>
                      <tr>
                        <td colspan="3"> <p style="text-align:left"> Date : {{ currentStudent.payment_date }}   </p><p style="text-align:right">Reçu par : 0002</p></td>

                      </tr>

                    </table>
                  </div></body>
              </html>
            </section>
          </vue-html2pdf>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Liste des réductions :
    </h4>
    <div class="d-flex ">
      <b-col
        md="4"
      />
      <b-col
        md="12"
        class="mt-2"
      >
        <b-form-group>
          <b-button
            :to="{ name: 'discount-add', params: { id: students.id} }"
            variant="gradient-primary"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Ajouter une réduction</span>
          </b-button>
        </b-form-group>
      </b-col>
    </div>
    <vue-good-table
      :columns="columnss"
      :rows="discounts"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'teaching-unit-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteTeachingUnit(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->

    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect, BButton, BTooltip, BCol, BFormInput,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import storeAuth from '@/store/store'
import { ValidationProvider } from 'vee-validate'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BTooltip,
    BCol,
    BFormInput,
    ValidationProvider,
    VueHtml2pdf,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      semesters: ['semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      currentStudent: {},
      studentt: {},

      columns: [
        {
          label: 'Actions',
          field: 'action',
        },
        {
          label: 'Montant',
          field: 'amount',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Motif',
          field: 'reason_payment',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Mode paiement',
          field: 'payment_method',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Date opération',
          field: 'operation_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Banque',
          field: 'bank',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'N° Chéque/Vir',
          field: 'n_check',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'N° Recu',
          field: 'n_received',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Date paiement',
          field: 'payment_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },

        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
      ],
      columnss: [
        {
          label: 'Montant',
          field: 'amount',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Motif réduction',
          field: 'reason_discount',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Date opération',
          field: 'operation_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Description',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },

        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },

      ],
      paiements: [],
      inscriptions: [],
      students: [],
      discounts: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},

    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getInscriptions()
    this.getAcademicYears()
    this.getPaiements()
    this.getStudents()
    this.getDiscounts()
    this.user = storeAuth.state.user
    this.student_id = this.$route.params.id
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    generateReport(student) {
      // console.log('id user', id)
      this.currentStudent = student
      // console.log('student-------', this.currentStudent)
      // console.log('studentt test-------', this.studentt)
      // console.log('studentttttt-------', this.currentStudent.parcour_name)
      this.$refs.html2Pdf.generatePdf()
    },
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getStudents() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/authentication/users/detail/${id}`)
      this.students = response.data
      this.studentt = response.data
      this.load = 'false'
    },
    async getInscriptions() {
      const response = await axios.get('/api/inscriptions/',
        {
          params: {
            academic_year_id: this.currentAcademicYear.id, student_id: this.$route.params.id,
          },
        })
      this.inscriptions = response.data
    },
    async getPaiements() {
      this.load = 'true'
      const response = await axios.get('/api/payments/', {
        params: {
          academic_year_id: this.currentAcademicYear.id, student_id: this.$route.params.id,
        },
      })
      this.paiements = response.data
      this.load = 'false'
    },
    async getDiscounts() {
      this.load = 'true'
      const response = await axios.get('/api/payments/discount/', {
        params: {
          academic_year_id: this.currentAcademicYear.id, student: this.$route.params.id,
        },
      })
      this.discounts = response.data
      this.load = 'false'
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
table, th, td {
  border: 0.5px solid black;
  border-collapse: collapse;
}
</style>
